import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";
import { logout } from "../actions/auth";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Balance: []
    };
    this.logOut = this.logOut.bind(this);
  }
  componentDidMount() {
  }

  logOut() {
    this.props.dispatch(logout());
  }


  render() {
    const { user: currentUser } = this.props;
    return (
   

      <div>

        <div className="nav-header">
          <a href="/" className="brand-logo">
            <span className="logo-abbr">
            <img className="" src="../assets/images/logo.png" style={{height: "50px", width: "50px" }}  />
             </span>
            <span className="brand-title" style={{color:"#558b2f",fontSize : "35px"}}> Wallet </span>
          </a>
          <div className="nav-control">
            <div className="hamburger">
              <span className="line"></span><span className="line"></span><span className="line"></span>
            </div>
          </div>
        </div>


        <div className="header">
          <div className="header-content">
            <nav className="navbar navbar-expand">
              <div className="collapse navbar-collapse justify-content-between">
                <div className="header-left">
                  <div className="input-group search-area right d-lg-inline-flex d-none">
                  </div>
                </div>
                <ul className="navbar-nav header-right main-notification">
                  <li className="nav-item dropdown header-profile">
                    <a className="nav-link" href="#" role="button" data-toggle="dropdown">
                      <img src="//ssl.gstatic.com/accounts/ui/avatar_2x.png" width="20" alt="" />
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}
export default connect(mapStateToProps)(Header);
