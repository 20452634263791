import React, { Component } from "react";
import { Modal, Button, FormControl } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";
import { ToastContainer, toast } from 'react-toastify';

class Buycoinpop extends Component {

  handleOnClick(datas) {
    const { proceed } = this.props;
    if (!datas) {
      return () => {
        proceed({
          datas
        })
      }
    }
    
    return () => {
      if (this.Amount === undefined) {
        toast.error("Please Enter Amount");
        return;
      }

      if (parseFloat(this.Amount) < 10) {
        toast.error("Amount must be select 10 to 3000.");
        return;
      }
      if (parseFloat(this.Amount) > 3000) {
        toast.error("Amount must be select 10 to 3000.");
        return;
      }
      proceed(
        {
          datas: {
            Address: this.Address,
            Amount: this.Amount,
            ticker: this.ticker
          }
        });
    };
  }

  buyamount = (e) => {
    this.Amount = e.target.value;
  }

  handleClick = (item) => {
    this.Address = item.target.value;
  }

  render() {
    const { show, message, rate, Addresses, ticker } = this.props;
    if (this.Address == undefined)
      this.Address = Addresses[0];
    this.ticker = ticker;
    return (

      <div className="static-modal">
        <Modal show="show" backdrop="static" style={{ cursor: "auto" }}>
          <Modal.Header>
            <h4 className="modal-title text-danger">{message}</h4>

          </Modal.Header>
          <Modal.Body>
            {/* <h5 className="text-danger"> {message} </h5> */}

            <div className="form">
              <div className="row">

                <div className="form-group col-md-12">
                  <label htmlFor="txtAmount" className="animated bounceInLeft  animationDelay100 " style={{ color: "black" }}> Amount in dollars($) </label>
                  <input className="form-control form-control-user animated bounceInLeft  animationDelay100" id="txtAmount" placeholder="0.00"
                    style={{ color: "black" }} onChange={this.buyamount} />
                </div>

                <div className="form-group col-md-12">
                  <label htmlFor="txtfee" className="animated bounceInLeft animationDelay100" style={{ color: "black" }}> Fee  </label>
                  <input className="form-control form-control-user animated bounceInLeft  animationDelay100" id="txtfee"
                    value={"1 " + ticker + " = $ " + rate + " "} readOnly style={{ color: "black" }} />
                </div>

                <div className="form-group col-md-12">
                  <label htmlFor="ddladdress" className="animated bounceInLeft  animationDelay100 " style={{ color: "black" }}> Select Address </label>
                  <select className="form-control" onChange={this.handleClick} id="ddladdress">
                    {Addresses.map(item => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </Modal.Body>
        <Modal.Footer>

            <Button
              variant="danger"
              bsStyle="danger"
              onClick={this.handleOnClick(false)}>Cancel
            </Button>

            <Button
              className="btn btn-primary"
              bsStyle="primary"
              backdrop="static"
              onClick={this.handleOnClick(true)}>Confirm
            </Button>

          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export const buycoin = createConfirmation(confirmable(Buycoinpop));
