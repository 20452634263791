import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";
import ContentHeader from "./content-header.component";
import Sidebar from "./sidebar.component";
import Loader from "./Loader-component"
import Header from "./Header.component";
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import Highcharts from 'highcharts';
import { buycoin as confirmComplex } from "./buycoin-component";
import {
	HighchartsSparkline, withHighcharts, AreaSeries, Tooltip
} from 'react-jsx-highcharts';


class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			coinDetail: [],
			isLoading: true,
			cointicker: ""
		};
	}

	componentWillMount() {
		var cointkr = this.props.match.params.coin;
		if (cointkr == undefined || cointkr == "") {
			this.setState({
				cointicker: "OWC"
			});
			cointkr = "OWC";
		}
		else {
			this.setState({
				cointicker: cointkr
			})
		}

		UserService.getCoindetail().then(
			response => {
				if (response.data.code == 100) {
					this.setState({
						coinDetail: response.data.coinDetail,
						isLoading: false
					});
				}
			},
			error => {
				this.setState({
					content:
						(error.response && error.response.data) ||
						error.message ||
						error.toString(),
					isLoading: false
				});
			}
		);
	}

	handleClick = (event) => {
		this.setState({
			loading: true,
		});
		event.preventDefault();
		this.handleOnClickComplex(this.state.coinDetail[0], "Buy Oduwacoin With CREDIT OR DEBIT CARD", 'OWC');
	}

	handleBTCClick = (event) => {
		this.setState({
			loading: true,
		});
		event.preventDefault();

		this.handleOnClickComplex(this.state.coinDetail[2], "Buy Bitcoin With CREDIT OR DEBIT CARD", "BTC");
	}

	handleOnClickComplex(data, msg, ticker) {
		
		confirmComplex({ message: msg, rate: data.rate, Addresses: data.Addresses, ticker: ticker }).then(
			({ datas }) => {
				if (datas) {
					if (ticker === "BTC") {
						this.setState({
							loading: false,
						});
						window.location.href = "https://indacoin.com/payment/en?partner=oduwacoin&cur_from=USD&cur_to=BTC&amount=" + parseFloat(datas.Amount) + "&user_id=" + data.pubkey + "&address=" + datas.Address;
					}
					else {
						UserService.buycoin(data.pubkey, parseFloat(datas.Amount), datas.Address).then(
							response => {
								if (response.code == 100) {
									window.location.href = response.data;
									toast.success(response.msg);
									this.setState({
										loading: false,
									});
								}
								else if (response.code == 500) {
									return <Redirect to="/login" />;
								}
								else {
									toast.error(response.msg);
									this.setState({
										loading: false,
									})
								}
							},
							error => {
								toast.error(error);
								this.setState({
									loading: false,
								});
							});
					}
				}
				else {

					this.setState({
						loading: false,
					});
				}
			}
		);
	}

	render() {
		const { user: currentUser } = this.props;

		if (!currentUser) {
			return <Redirect to="/login" />;
		}

		return (
			<div>
				<Header />
				<Sidebar coin={this.state.cointicker} />
				<div className="content-body">
					<div className="container-fluid">

						<div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
							<h2 className="font-w600 mb-2 mr-auto animated bounceInLeft  animationDelay500">Dashboard</h2>
							<ContentHeader coin={this.state.cointicker} />
						</div>
						{
							
							this.state.isLoading ?
								<Loader />
								:
								<div className="row">
									<div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<div className="card animated bounceInLeft">
											<div className="card-header">
												<h5 className="card-title animated bounceInLeft  animationDelay500">Buy Coin With Credit or Debit Card</h5>
											</div>
											<div className="card-body">
												<div className="row">
													<div className="form-group col-md-3 col-sm-6">
														<button
															className="btn btn-primary btn-block"
															disabled={this.state.loading}
															onClick={this.handleClick}
														>
															{this.state.loading && (
																<span className="spinner-border spinner-border-sm"></span>
															)}
															<span>Buy OWC</span>
														</button>
													</div>
													<div className="form-group col-md-3 col-sm-6">
														<button
															className="btn btn-primary btn-block"
															disabled={this.state.loading}
															onClick={this.handleBTCClick}
														>
															{this.state.loading && (
																<span className="spinner-border spinner-border-sm"></span>
															)}
															<span>Buy BTC</span>
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<br></br>
									{this.state.coinDetail.map((data, index) =>
										<div className="col-xl-4 col-xxl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 m-t35 animated zoomInDown animationDelay100" key={index} >
											<a href={"/wallet/" + data.Ticker} className="Acontent" >
												<div className="card card-coin">
													<div className="card-body text-center">
														<img className="mb-3 currency-icon" src={"../assets/images/" + data.Ticker + ".png"} style={{ width: "80px", height: "80px" }} />
														<h2 className="text-black mb-3 font-w600">{data.ConfirmedBalance + "   " + data.Ticker}  </h2>

														<div className="row sp20 mb-2 align-items-center">

															<div className="d-flex col-lg-12 col-xxl-12 col-sm-12  col-xs-12 align-items-center mt-sm-0 mt-3 justify-content-center">
																<div className="px-2 info-group">
																	<p className="fs-14 mb-1">USD Balance</p>
																	<h3 className="fs-20 font-w600 text-black">$ {parseFloat(data.Confirmed_USDBalance).toFixed(4)}</h3>
																</div>
																<div className="px-2 info-group">
																	<p className="fs-14 mb-1">24h% change</p>
																	{
																		data.change_percentage_24h > 0 ?
																			<h3 className="fs-20 font-w600"><span className="text-success">{data.change_percentage_24h.toFixed(2)} %</span>
																				<i className="fa fa-caret-up scale5 ml-2 text-success" aria-hidden="true"></i>
																			</h3>
																			:
																			<h3 className="fs-20 font-w600"><span className="text-danger">

																				{data.change_percentage_24h == null ?
																					0
																					:
																					data.change_percentage_24h.toFixed(2)
																				}
																				% </span>

																				<i className="fa fa-caret-down scale5 ml-2 text-danger" aria-hidden="true"></i>
																			</h3>
																	}
																</div>
															</div>
														</div>



														<div className="row sp20 mb-2 align-items-center">
															<HighchartsSparkline height="80" width="450"
																series={
																	<AreaSeries name={data.Ticker} data={data.Chartdata} color={data.color} />
																}>
																<Tooltip
																	outside
																	borderWidth={3}
																	shadow={false}
																	hideDelay={0}
																	padding={8}
																	headerFormat={`<b>${data.Ticker}:</b> `}
																	pointFormat={'{point.y:,.6f}'} />
															</HighchartsSparkline>
														</div>
													</div>
												</div>

											</a>
										</div>

									)}
								</div>
						}
					</div>
				</div>
				<div className="footer">
					<div className="copyright">
						<p>Copyright ©  <a href="/" target="_blank">Oduwa Trust Wallet</a> 2021</p>
					</div>
				</div>

			</div>

		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(withHighcharts(Dashboard, Highcharts));





