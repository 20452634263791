import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
var QRCode = require('qrcode');

class Intro extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentWillMount() {
    }

    render() {
        return (
            <div>
                <div className="row justify-content-center">
                    <div className="col-md-8 mt-5" >
                        <div className="card animated zoomInDown">
                            <div className="card-body">
                                <div id="smartwizard" className="form-wizard order-create">

                                    <ul className="nav nav-wizard">
                                        <li><a className="nav-link" href="#step1">
                                            <span>1</span>
                                        </a></li>
                                        <li><a className="nav-link" href="#step2">
                                            <span>2</span>
                                        </a></li>
                                        <li><a className="nav-link" href="#step3">
                                            <span>3</span>
                                        </a></li>
                                    </ul>


                                    <div className="tab-content">
                                        <div id="step1" className="tab-pane" role="tabpanel">
                                            <div className="row">
                                            <div className="col-md-7">
                                                <div className="wizard1"></div>
                                            </div>
                                                
                                            <div className="col-md-5 wizarddesc">
                                                <div className =" text-center ">
                                                <h2 className="text-primary animated bounceInLeft  animationDelay100">Wallet Coin</h2>
                                                <p className="h4  animated bounceInLeft  animationDelay200">Save your Oduwa Trust Wallet securely using the existing paper wallet method. This helps you to recover your coins in case of any emergency.Remember to backup your recovery phrase!</p>
                                               
                                                </div>
                                            </div>

                                            </div>

                                        </div>
                                        <div id="step2" className="tab-pane" role="tabpanel">
                                            <div className="row">
                                            <div className="col-md-7">
                                                <div className="wizard2"></div>
                                            </div>
                                           
                                            <div className="col-md-5 wizarddesc">
                                            <div className =" text-center">
                                            <h2 className="text-primary animated bounceInLeft  animationDelay100">Receive Coin</h2>
                                             <p className="h4  animated bounceInLeft  animationDelay200">conveniently scan the QR code to gather receiving address and Share with the world!</p>
                                             
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div id="step3" className="tab-pane" role="tabpanel">
                                            <div className="row">
                                            <div className="col-md-7">
                                                <div className="wizard3"></div>
                                           </div>

                                            <div className="col-md-5 wizarddesc">
                                            <div className =" text-center">
                                            <h2 className="text-primary animated bounceInLeft  animationDelay100">Send Coin</h2>
                                            <p className="h4 animated bounceInLeft  animationDelay200">Instantly send your coin  with Oduwa Trust Wallet a single tap or scan QR code to automatically get the Oduwa Trust Wallet address</p>
                                            
                                            </div>
                                            </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
}
export default connect(mapStateToProps)(Intro);





