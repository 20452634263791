import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route ,Redirect} from "react-router-dom";
import Cookies from 'js-cookie';

import "./App.css";

import Login from "./components/login.component";
import Dashboard from "./components/dashboard.component";
import { logout } from "./actions/auth";
import { clearMessage } from "./actions/message";
import { history } from './helpers/history';
import CreateWallet from "./components/CreateWallet-component";
import NotFound from './components/NotFound.component';
import Wallet from "./components/wallet-component";
import AnimatedCursor from "react-animated-cursor";
import intro from "./components/intro-component";
import privacyPolicy from "./components/privacyPolicy-component";
import CopyrightPolicy from "./components/copyrightpolicy-component";
import Disclaimer from "./components/disclaimer-component";
import TermsConditions from "./components/termsconditions-component";
class App extends Component {
  constructor(props) {
    super(props);    
    this.logOut = this.logOut.bind(this);
    this.state = {
      currentUser: undefined,
      issintro :undefined
      
    };
    history.listen((location) => {
      props.dispatch(clearMessage()); // clear message when changing location
    });
  }

  componentDidMount() {
    const user = this.props.user;
    var  isintro =  Cookies.get("intro");

    console.log(isintro);
    
    this.setState({
      issintro: isintro,
    });
    

    if (user) 
    {
      if( sessionStorage.getItem('key'))
      {
        this.setState({
          currentUser: user,
        });
      }
   }

   else
   {
    return <Redirect to="/login" />;
   }
}

  logOut() {
    this.props.dispatch(logout());
  }

  render() {
    const { currentUser } = this.state;
    return (
      <Router history={history}>
        <div>
        <AnimatedCursor
      innerSize={12}
      outerSize={12}
      color='7, 90, 12'
      outerAlpha={0.4}
      innerScale={0.9}
      outerScale={8}
      />
          <Switch>
          
              { 
               !this.state.issintro ?
               <Route exact path={["/", "/intro"]} component={intro} />
               :
               <Route exact path={["/", "/login"]} component={Login} />
              }
               <Route path="/privacypolicy" component={privacyPolicy} />
 	      <Route path="/copyright" component={CopyrightPolicy} />
              <Route path="/term" component={TermsConditions} />
              <Route path="/disclaimer" component={Disclaimer} />
              <Route path="/login" component={Login} />
              <Route path="/dashboard/:coin?" component={Dashboard} />
              <Route path="/wallet/:coin?" component={Wallet}  />
              <Route path="/CreateWallet" component={CreateWallet} />
              <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </Router>
     );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(App);
