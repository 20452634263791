import React, { Component, NavLink } from "react";
import UserService from "../services/user.service";
import { Redirect } from 'react-router-dom';
import _ from "lodash";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { connect } from "react-redux";
import { logout } from "../actions/auth";

// import SidebarCoin from "./sidebarcoin-component";
import { ToastContainer, toast } from 'react-toastify';
import { combineReducers } from "redux";
var ws;
var messageID;
var subscriptions = [];
var pendingMessages;
var subscribeAddressesId = "";



class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Balance: [],
      ws: null,
      coindetail: []

    };

    this.subscribe = this.subscribe.bind(this);
    this.subscribeAddresses = this.subscribeAddresses.bind(this);
    this.connect = this.connect.bind(this);
    this.logOut = this.logOut.bind(this);

  }

  componentWillMount() {

    UserService.getCoindetail().then(
      response => {

        if (response.data.code == 100) {
          this.setState({
            coindetail: response.data.coinDetail
          });


          for (var i = 0; i < response.data.coinDetail.length; i++) {
            if (this.state.coindetail[i].Ticker == this.props.coin) {
              this.connect(this.state.coindetail[i].ExpolrerUrl, this.state.coindetail[i].Addresses);
            }

          }
        }
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString(),
          isLoading: false
        });
      }
    );

  }

  logOut() {
    this.props.dispatch(logout());
  }

  timeout = 200;
  unsubscribe(method, id, params, callback) {
    delete subscriptions[id];
    pendingMessages[id] = callback;
    var req = {
      id,
      method,
      params
    }
    ws.send(JSON.stringify(req));
    return id;
  }

  subscribe(method, params, callback) {
    var id = messageID.toString();
    messageID++;
    subscriptions[id] = callback;

    var req = {
      id,
      method,
      params
    }

    if (ws.readyState !== WebSocket.OPEN) return;
    ws.send(JSON.stringify(req));
    return id;
  }


  connect(server, Addresses) {
    messageID = 0;
    var pendingMessages = {};
    var subscriptions = {};
    var connectInterval;

    if (server.startsWith("http")) {
      server = server.replace("http", "ws");
    }
    if (!server.endsWith("/websocket")) {
      server += "websocket";
    }

    ws = new W3CWebSocket(server);

    ws.onopen = (e) => {
      //  console.log('connnectt', e);
      this.setState({ ws: ws });
      this.timeout = 250; // reset timer to 250 on open of websocket connection 
      clearTimeout(connectInterval);
      this.subscribeAddresses(Addresses);
    };
    ws.onclose = (e) => {
      // console.log('socket closed', e);
      var url = e.target.url.replace("ws", "http");
      this.timeout = this.timeout + this.timeout; //increment retry interval
      connectInterval = setTimeout(() => {
        this.check(url)  //call check function
      }, Math.min(10000, this.timeout));


    };

    ws.onerror = (e) => {
      // console.log('socket error ', e);
    };
    ws.onmessage = (e) => {

      var resp = JSON.parse(e.data);
      var f = resp.data.address;
      if (f != undefined) {
        var coinname;
        if (resp.data.address.startsWith("A")) {
          coinname = "OWC";
        }
        else if (resp.data.address.startsWith("o")) {
          coinname = "OWDT";
        }
        else {
          coinname = "BTC";
        }

        toast.info("New Transaction on " + coinname + " " + resp.data.tx.txid);
        setTimeout(function () {
          window.location.reload();
        }, 10000);
      }
      else {

      }

    };
  }

  check = async (url) => {
    const { ws } = this.state;
    url = url.replace("websocket", "")
    if (!ws || ws.readyState == WebSocket.CLOSED) {
      var data = await _.find(this.state.coindetail, { ExpolrerUrl: url });
      this.connect(data.ExpolrerUrl, data.Addresses); //check if websocket instance is closed, if so call `connect` function.
    }
  };

  subscribeAddresses(Addresses) {
    const method = 'subscribeAddresses';
    const params = {
      Addresses
    };
    if (subscribeAddressesId) {
      delete subscriptions[subscribeAddressesId];
      subscribeAddressesId = "";
    }

    subscribeAddressesId = this.subscribe(method, params, function (result) {
      return result;
    });
  }


  render() {
    const dashboardClass = window.location.href.includes("dashboard") ? "mm-active" : "";
    const settingclass = window.location.href.includes("setting") ? "mm-active" : "";
    const Walletclass = window.location.href.includes("/wallet") ? "mm-active" : "";
    return (
      <div>
        <ToastContainer />

        <div className="deznav">
          <div className="deznav-scroll">
            <ul className="metismenu" id="menu">

              <li className={dashboardClass}>
                <a className=" ai-icon" href={'/dashboard/' + this.props.coin} >
                  <i className="flaticon-144-layout"></i>
                  <span className="nav-text">Dashboard</span>
                </a>
              </li>

              <li className={Walletclass}><a className="has-arrow ai-icon" aria-expanded="false">
                <i className="flaticon-381-network"></i>
                <span className="nav-text">Wallet</span>
              </a>
                <ul aria-expanded="false">
                  <li>
                    <a className=" ai-icon" href="/wallet/OWC">
                      <i className={'cc  OWC'} title='OWC'></i>
                      <span className="">Oduwa </span>
                    </a>
                  </li>

                  <li>
                    <a className="ai-icon" href="/wallet/OWDT">
                      <i className={'cc  OWDT'} title='OWDT'></i>
                      <span className="">OduwaUSD </span>
                    </a>
                  </li>
                  
                  <li>
                    <a className=" ai-icon" href="/wallet/BTC">
                      <i className={'cc  BTC'} title='BTC'></i>
                      <span className="">Bitcoin </span>
                    </a>
                  </li>

                </ul>
              </li>

              <li>  <a className=" ai-icon" onClick={this.logOut}>
                <i className="fa fa-sign-out"></i>
                <span className="nav-text">Logout</span>
              </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Sidebar);
