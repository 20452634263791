import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import Loader from "./Loader-component"


class TransactionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      txList: [],
      balance: 0,
      txs: 0,
      totalReceived: 0,
      totalSent: 0,
      isLoading: true,
      cointicker: "",
      Explorerurl: "",
      activePage: 1,
      itemPerPage: 10
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }

  componentWillMount() {
    var cointkr = this.props.coin;
    if (cointkr == undefined) {
      this.setState({
        cointicker: "OWC"
      });
      cointkr = "OWC"
    }
    else {
      this.setState({
        cointicker: cointkr
      })
    }

    UserService.gettransaction(cointkr).then(
      response => {
        if (response.data.code == 100) {
          if (response.data.txs > 0) {

            this.setState({
              txList: response.data.txlist,
            });

          }
          this.setState({
            txs: response.data.txs,
            totalReceived: response.data.totalReceived,
            totalSent: response.data.totalSent,
            balance: response.data.balance,
            isLoading: false,
            Explorerurl: response.data.Explorerurl
          });
        }
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString(),
          isLoading: false
        });
      }
    );
  }

  render() {
    const { user: currentUser } = this.props;


    var indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
    var indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
    var renderedtx = this.state.txList.slice(indexOfFirstTodo, indexOfLastTodo);


    var listItems = renderedtx.map((data, index) => {
      return <tr role="row" key={index}>
        <td className="pr-0 sorting_1">
          {data.type == "send" ?


            <span className="bg-success ic-icon">
              <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.7529 19.1563L7.5703 5.97367C7.00891 5.41228 6.09876 5.41228 5.53737 5.97367C4.97598 6.53505 4.97598 7.44521 5.53737 8.0066L18.72 21.1892L15.2913 21.1809L15.2912 21.1809C14.4973 21.179 13.8522 21.8211 13.8503 22.615C13.8484 23.4091 14.4907 24.054 15.2844 24.056L15.2856 23.556L15.2844 24.056L22.1954 24.0727L22.2028 24.0727L22.2051 24.0726C22.9947 24.0692 23.6329 23.4284 23.6363 22.6414L23.6363 22.6391L23.6363 22.6317L23.6196 15.7207L23.6196 15.7207C23.6177 14.9268 22.9727 14.2847 22.1787 14.2866C21.3847 14.2885 20.7427 14.9336 20.7446 15.7275L20.7446 15.7275L20.7529 19.1563Z" fill="white" stroke="white"></path>
              </svg>
            </span>
            :
            <span className="bg-danger ic-icon">
              <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.99954 10.4687L21.1821 23.6513C21.7435 24.2127 22.6537 24.2127 23.2151 23.6513C23.7765 23.0899 23.7765 22.1798 23.2151 21.6184L10.0325 8.43582L13.4612 8.4441L13.4612 8.44409C14.2551 8.44598 14.9002 7.80394 14.9021 7.01004C14.904 6.21593 14.2617 5.57098 13.468 5.56905L13.4668 6.06905L13.468 5.56905L6.55703 5.55234L6.54969 5.55232L6.54737 5.55239C5.75771 5.55578 5.11953 6.19662 5.11616 6.98358L5.1161 6.98585L5.11612 6.99333L5.13282 13.9043L5.13282 13.9043C5.13475 14.6982 5.77979 15.3403 6.57378 15.3384C7.36769 15.3365 8.00975 14.6914 8.00787 13.8975L8.00787 13.8975L7.99954 10.4687Z" fill="white" stroke="white"></path>
              </svg>
            </span>
          }
        </td>

        <td><span style={{ wordBreak: "break-all" }}><a href={this.state.Explorerurl + "tx/" + data.txid} target="_blank" >{data.txid}</a></span></td>

        <td> {data.type == "send" ?
          <span className="text-success font-w600  animated bounceInRight animationDelay500">{(data.FinalAmount).toFixed(8)} {this.state.cointicker}</span>
          :
          <span className="text-danger font-w600  animated bounceInRight animationDelay500">{(data.FinalAmount).toFixed(8)} {this.state.cointicker}</span>
        }
        </td>

        <td className="wspace-no">
          <img className="" width="40" height="40" src={"../assets/images/" + this.state.cointicker + ".png"} />
          {/* <span className="text-black font-w600  animated bounceInRight animationDelay500">{this.state.cointicker}</span> */}
        </td>

        <td>
          <div className="d-flex align-items-center">
            <span className="text-black font-w600 animated bounceInRight animationDelay500">{data.confirmations}  Confirmation</span>
          </div>
        </td>
        <td>
          <span className="text-black font-w600">{data.time}</span>
        </td>
      </tr>
    });

    if (!currentUser) {
      return <Redirect to="/login" />;
    }

    return (
      
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
            <div className="card animated zoomInDown">

              <div className="card-header">
                <h5 className="card-title animated bounceInLeft  animationDelay500">Transaction History</h5>
              </div>
              <div className="card-body">
              {
							this.state.isLoading ?
								<Loader />
								:
                
                this.state.txs > 0 ?
                  <div>
                    <div className="table-responsive fs-14">
                      <table className="table table-hover table-responsive-sm mb-4  text-black">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Transaction ID</th>
                            <th>Amount</th>
                            <th>Coin</th>
                            <th>Confirmation</th>
                            <th>Date</th>

                          </tr>
                        </thead>
                        <tbody>
                          {listItems}
                        </tbody>
                      </table>
                      <div className="text-left mt-5">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemPerPage}
                          totalItemsCount={this.state.txList.length}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange.bind(this)}
                          hideFirstLastPages={true}
                          prevPageText="Previous"
                          nextPageText="Next"
                          linkClass="paginate_button"
                          linkClassPrev=" previous"
                          linkClassNext=" next"
                          activeLinkClass="active"
                          hideDisabled={true}
                        />
                      </div>
                    </div>
                  </div> :
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                    <div className="box box-outline-primary flex-column text-center m-4 animated zoomInDown animationDelay500">
                      <span className="avatar avatar-xxl bg-danger opacity-60 mx-auto mt-10">
                        <i className="align-sub glyphicon glyphicon-remove pt-20 font-size-50"></i>
                      </span>
                      <div className="mt-10">
                        <h4 className="text-uppercase  text-danger">Transaction not Found !</h4>
                      </div>
                    </div>
                  </div>

                }
          


              </div>

            </div>
          </div>
      
     
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}
export default connect(mapStateToProps)(TransactionHistory);





