import axios from 'axios';
const user = (sessionStorage.getItem('key'));

class UserService {
  getBalance(ticker) {
    return axios.get('/getbalance', { params: { "ticker": ticker,"mnemonic" : user} });
  }

  getaddresses(ticker) {
    return axios.get('/getaddresses', {  params: { "ticker": ticker,"mnemonic" : user } });

  }

  gettransaction(ticker) {
    return axios.get('/gettransaction', { params: { "ticker": ticker ,"mnemonic" : user }});
  }

  getCoindetail() {
    
    return axios.get('/getCoindetail',{ params: {"mnemonic" : user }});
  }

  getCoin() {
    return axios.get('/getCoin', { params:{ "mnemonic" : user}});
  }

  checkbalance(ticker, ToAddress, Amount) {
    return axios.get('/checkbalance', {  params: { "ticker": ticker, "ToAddress": ToAddress, "Amount": Amount,"mnemonic" : user } });
  }

  getmnemonic() {
    return axios.get('/getmnemonic', { params:{"mnemonic" : user }});
  }

  CreateWallet() {
    return axios.get('/CreateWallet', { params:{ "mnemonic" : user}});
  }

  send(ticker, txs) {
    return axios
      .post("/send", { params: { "ticker": ticker, "txs": txs,"mnemonic" : user } })
      .then((response) => {
        return response.data;
      });
  }

  buycoin(md5pubkey, amount, address) {
    return axios
      .post("/buycoin", { params: { "email": md5pubkey, "amount": amount, "address": address } })
      .then((response) => {
        return response.data;
      });
  }

  buycoinhistory(ticker) {
    return axios
      .get("/buycoinhistory", { params: { "ticker": ticker,"mnemonic" : user } })
      .then((response) => {
        return response.data;
      });
  }

  
}
export default new UserService();