import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";

class CopyrightPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {}

    render() {
        return (
            <div>
                <div className="row justify-content-center">
                    <div className="col-md-8 mt-5">
                        <div>
                            <h1 style={{ paddingTop: "3pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "center" }}>COPYRIGHT POLICY</h1>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>Copyright © Oduwa Blockchain Solutions LLC. All Rights Reserved.</p>

                            <p style={{ paddingTop: "10pt", paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                All files and information contained in this Mobile App are copyright by Oduwa Blockchain Solutions LLC. and may not be duplicated, copied, modified or adapted, in any
                                way without our written permission. Our App may contain our service marks or trademarks as well as those of our affiliates or other companies, in the form of words,
                                graphics, and logos.
                            </p>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Your use of our App, or their Services does not constitute any right or license for you to use our service marks or trademarks, without the prior written permission of
                                Oduwa Blockchain Solutions LLC.
                            </p>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Our Content, as found within our App, and Services, is protected under copyrights. The copying, redistribution, use or publication by you of any such Content, is
                                strictly prohibited. Your use of our App and Services does not grant you any ownership rights to our Content.
                            </p>

                            <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>ACCEPTABLE USE</h2>

                            <h2 style={{ paddingTop: "10pt", paddingLeft: "35pt", textIndent: "0pt", textAlign: "left" }}>You must not:</h2>

                            <ol id="l1">
                                <li data-list-text="(a)">
                                    <p style={{ paddingLeft: "65pt", textIndent: "-30pt", lineHeight: "150%", textAlign: "left" }}>
                                        use our app in any way or take any action that causes, or may cause, damage to the app or impairment of the performance, availability or accessibility of the
                                        app;
                                    </p>
                                </li>
                                <li data-list-text="(b)">
                                    <p style={{ paddingTop: "10pt", paddingLeft: "65pt", textIndent: "-30pt", lineHeight: "150%", textAlign: "left" }}>
                                        use our app in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;
                                    </p>
                                </li>
                                <li data-list-text="(c)">
                                    <p style={{ paddingTop: "10pt", paddingLeft: "65pt", textIndent: "-30pt", lineHeight: "150%", textAlign: "left" }}>
                                        use our app to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus,
                                        Trojan horse, worm, keystroke logger, rootkit or other malicious computer software; or
                                    </p>
                                </li>
                                <li data-list-text="(d)">
                                    <p style={{ paddingTop: "3pt", paddingLeft: "65pt", textIndent: "-30pt", lineHeight: "150%", textAlign: "left" }}>
                                        conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or
                                        in relation to our app without our express written consent.
                                    </p>
                                </li>
                            </ol>
                            <h2 style={{ paddingTop: "9pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>REPORT ABUSE</h2>

                            <p style={{ paddingLeft: "35pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                If you found any unlawful material or activity on our app, or any material or activity that breaches this notice, please let us know.
                            </p>
                            <p style={{ paddingTop: "10pt", paddingLeft: "35pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                <a href="mailto:Support@oduwablockchain.com" className="a" target="_blank">
                                    You can let us know about any such material or activity by email us at{" "}
                                </a>
                                <a href="mailto:Support@oduwablockchain.com" target="_blank">
                                    Support@oduwablockchain.com.
                                </a>
                            </p>
                            <h2 style={{ paddingTop: "9pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>ENFORCEMENT OF COPYRIGHT</h2>

                            <p style={{ paddingLeft: "35pt", textIndent: "0pt", textAlign: "left" }}>We take the protection of our copyright very seriously.</p>

                            <p style={{ paddingLeft: "35pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                If we discover that you have used our copyright materials in contravention of the license set out in this notice, we may bring legal proceedings against you, seeking
                                monetary damages and/or an injunction to stop you using those materials. You could also be ordered to pay legal costs.
                            </p>
                            <h2 style={{ paddingTop: "9pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>PERMISSIONS</h2>

                            <p style={{ paddingLeft: "35pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                <a href="mailto:Support@oduwablockchain.com" className="a" target="_blank">
                                    You may request permission to use the copyright materials on our app by email us at{" "}
                                </a>
                                <a href="mailto:Support@oduwablockchain.com" target="_blank">
                                    Support@oduwablockchain.com.
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {}
export default connect(mapStateToProps)(CopyrightPolicy);

