import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Sidebar from "./sidebar.component";
import Header from "./Header.component";
import { Redirect } from 'react-router-dom';
import Send from "./send.component";
import Receive from "./receive.component";
import ContentHeader from "./content-header.component";
import TransactionHistory from "./transactionhistory.component"
import SwapHistory from "./Swaphistory.component"
import { Tabs, Tab } from 'react-bootstrap-tabs';
var QRCode = require('qrcode');
class Wallet extends Component {
	constructor(props) {
		super(props);

		this.state = {
			coinDetail: [],
			isLoading: true,
			cointicker: ""
		};
	}

	componentWillMount() {
		var cointkr = this.props.match.params.coin;

		if (cointkr == undefined || cointkr == "") {
			this.setState({
				cointicker: "OWC"
			});
			cointkr = "OWC";
		}
		else {
			this.setState({
				cointicker: cointkr
			})
		}

	}

	render() {
		const { user: currentUser } = this.props;
		if (!currentUser) {
			return <Redirect to="/login" />;
		}

		return (
			<div>
				<Header />
				<Sidebar coin={this.state.cointicker} />
				<div className="content-body">
					<div className="container-fluid">

						<div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
							<h2 className="font-w600 mb-2 mr-auto animated bounceInLeft  animationDelay500">{this.state.cointicker} Wallet Info</h2>
							<ContentHeader coin={this.state.cointicker} />
						</div>

						<Tabs  className="nav nav-pills   mb-4" contentClass="tabcontent-border">
							<Tab label="Send/Receive">
								<section className="content">
									<div className="row">
										<Send coin={this.state.cointicker} />
										<Receive coin={this.state.cointicker} />
									</div>
								</section>
							</Tab>
							
							<Tab label="Transaction History">
								<section className="content">
									<div className="row">
										<TransactionHistory coin={this.state.cointicker} />
									</div>
								</section>
							</Tab>

							<Tab label="Buy History">
								<section className="content">
									<div className="row">
										<SwapHistory coin={this.state.cointicker} />
									</div>
								</section>
							</Tab>
							
						</Tabs>
					</div>
				</div >
				<div className="footer">
					<div className="copyright">
						<p>Copyright ©  <a href="/" target="_blank">Oduwa Trust Wallet</a> 2021</p>
					</div>
				</div>
			
			</div >

		);
	}
}

function mapStateToProps(state) {
	const { user } = state.auth;
	return {
		user,
	};
}

export default connect(mapStateToProps)(Wallet);

