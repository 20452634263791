import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    SET_MESSAGE,
  } from "./types";
  
  import AuthService from "../services/auth.service";

  export const register = ( email, password) => (dispatch) => {
    return AuthService.register( email, password).then(
      (data) => 
      {
        if(data.code == 100)
        {
         dispatch({
           type: REGISTER_SUCCESS
         });

         dispatch({
          type: SET_MESSAGE,
          payload: data.msg,
        });
         
        }
        else
        {
          dispatch({
            type: REGISTER_FAIL,
          });
    
          dispatch({
            type: SET_MESSAGE,
            payload: data.msg,
          });
        }

        return data;
     
       },
      (error) => {
        dispatch({
          type: REGISTER_FAIL,
        });
        //   dispatch({
      //     type: SET_MESSAGE,
      //     payload: message,
      //   });
        return({data :{msg:"Internal Server Error"}})
      //   const message =
      //     (error.response &&
      //       error.response.data &&
      //       error.response.data.message) ||
      //     error.message ||
      //     error.toString();
      //   return Promise.reject();
      }
    );
  };
  
  export const login = (mnemonic) => (dispatch) => {
    return AuthService.login(mnemonic).then(
        (resp) => 
        {
          if(resp.code == 100)
          {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { user: resp },
            });
          }
          else
          {
            dispatch({
              type: LOGIN_FAIL,
            });
          }
          Promise.resolve();
          return resp;
        },
        (error) => {
          return({data :{msg:"Internal Server Error"}});
        }
      )
  };
 
  export const logout = () => (dispatch) => {
    AuthService.logout();
  
    dispatch({
      type: LOGOUT,
    });
  };

  
  
