import axios from "axios";
class AuthService {
  login(mnemonic) 
  {
    return axios
      .post( "/Login", { mnemonic })
      .then((response) => {
       if (response.data.code == 100) 
       {
        sessionStorage.setItem('key', response.data.mnemonic);
       }
        return response.data;
      });
  }
  logout() 
  {
    sessionStorage.removeItem('key');
  }
}

export default new AuthService();
