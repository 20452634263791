import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";
import { Redirect, Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import Loader from "./Loader-component"


class SwapHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      txList: [],
      txs: 0,
      isLoading: true,
      cointicker: "",
      Explorerurl: "",
      activePage: 1,
      itemPerPage: 10
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
  }

  componentWillMount() {
    var cointkr = this.props.coin;
    if (cointkr == undefined) {
      this.setState({
        cointicker: "OWC"
      });
      cointkr = "OWC"
    }
    else {
      this.setState({
        cointicker: cointkr
      })
    }

    UserService.buycoinhistory(cointkr).then(
      response => {
        if (response.code == 100) {
          this.setState({
            txList: response.data
          })
          this.setState({
            txs: response.data.length,
            isLoading: false,
          });
        }
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString(),
          isLoading: false
        });
      }
    );
  }

  render() {
    const { user: currentUser } = this.props;

    var indexOfLastTodo = this.state.activePage * this.state.itemPerPage;
    var indexOfFirstTodo = indexOfLastTodo - this.state.itemPerPage;
    var renderedtx = this.state.txList.slice(indexOfFirstTodo, indexOfLastTodo);
    var listItem = renderedtx.map((data, index) => {
      return <tr role="row" key={index}>

        <td>
          <span className="text-success font-w600  animated bounceInRight animationDelay500">{(data.amount)}</span>
        </td>
        <td>
          <div className="d-flex align-items-center">
            <span className="text-black font-w600 animated bounceInRight animationDelay500">{data.payAmount} </span>
          </div>
        </td>
        <td className="pr-0 sorting_1">
          <div className="d-flex align-items-center">
            <span className="text-black font-w600 animated bounceInRight animationDelay500">{data.address} </span>
          </div>
        </td>
        <td className="sorting_1"> {data.status == '0' ?
          <span className="text-warning font-w600  animated bounceInRight animationDelay500">Pending</span>
          : data.status == '1' ?
            <span className="text-warning font-w600  animated bounceInRight animationDelay500">Verification</span>
            : data.status == '2' ?
              <span className="text-warning font-w600  animated bounceInRight animationDelay500">Paid</span>
              : data.status == '3' ?
                <span className="text-warning font-w600  animated bounceInRight animationDelay500">Funds Send</span>
                : data.status == '4' ?
                  <span className="text-success font-w600  animated bounceInRight animationDelay500">Finished</span>
                  :
                  <span className="text-danger font-w600  animated bounceInRight animationDelay500">Trouble</span>
        }
        </td>
        <td>
          <span className="text-black font-w600">{data.createdAt}</span>
        </td>
        <td><span style={{ wordBreak: "break-all" }}>{data.hash}</span></td>
      </tr>
    });
    if (!currentUser) {
      return <Redirect to="/login" />;
    }

    return (

      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-5">
        <div className="card animated zoomInDown">

          <div className="card-header">
            <h5 className="card-title animated bounceInLeft  animationDelay500">Buy History</h5>
          </div>
          <div className="card-body">
            {
              this.state.isLoading ?
                <Loader />
                :
                this.state.txs > 0 ?
                  <div>
                    <div className="table-responsive fs-14">
                      <table className="table table-hover table-responsive-sm mb-4  text-black">
                        <thead>
                          <tr>  
                            <th>Amount</th>
                            <th>USD</th>
                            <th>Address</th>
                            <th>Status</th>
                            <th>Date</th>
                            <th>Transaction ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listItem}
                        </tbody>
                      </table>
                      <div className="text-left mt-5">
                        <Pagination
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemPerPage}
                          totalItemsCount={this.state.txList.length}
                          pageRangeDisplayed={10}
                          onChange={this.handlePageChange.bind(this)}
                          hideFirstLastPages={true}
                          prevPageText="Previous"
                          nextPageText="Next"
                          linkClass="paginate_button"
                          linkClassPrev=" previous"
                          linkClassNext=" next"
                          activeLinkClass="active"
                          hideDisabled={true}
                        />

                      </div>
                    </div>
                  </div> :
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                    <div className="box box-outline-primary flex-column text-center m-4 animated zoomInDown animationDelay500">
                      <span className="avatar avatar-xxl bg-danger opacity-60 mx-auto mt-10">
                        <i className="align-sub glyphicon glyphicon-remove pt-20 font-size-50"></i>
                      </span>
                      <div className="mt-10">
                        <h4 className="text-uppercase  text-danger">No data found !</h4>
                      </div>
                    </div>
                  </div>
            }

          </div>

        </div>
      </div>


    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}
export default connect(mapStateToProps)(SwapHistory);





