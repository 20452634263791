import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
var QRCode = require('qrcode');

class Receive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Addresses: [],
      isLoading: true,
      cointicker: ""
    };
    this.handleClick = this.handleClick.bind(this)
  }

  componentWillMount() {
    var cointkr = this.props.coin;
    if (cointkr == undefined || cointkr == "") {
      this.setState({
        cointicker: "OWC"
      });
      cointkr = "OWC";
    }
    else {
      this.setState({
        cointicker: cointkr
      })
    }


    UserService.getaddresses(cointkr).then(
      response => {
        if (response.data.code == 100) {
          this.setState({
            Addresses: response.data.Addresses,
            isLoading: false,
          });
          this.loadQRCodeData(response.data.Addresses[0]);
        }
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString(),
          isLoading: false,

        });
      }
    );
  }


  loadQRCodeData(Addreses) {
    this.setState({
      qraddress: Addreses
    });
    QRCode.toCanvas(document.getElementById('canvas'),
      Addreses, { toSJISFunc: QRCode.toSJIS }, function (error) {
        if (error) console.error(error)
        console.log('success!')
      })
  }

  handleClick = (item) => {
    this.loadQRCodeData(item.target.value);
  }

  render() {

    const { user: currentUser } = this.props;
    if (!currentUser) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-5 col-12">
        <ToastContainer />
        <div className="card  bounceInRight ">
          <div className="card-header">
            <h5 className="card-title animated bounceInLeft  animationDelay500">Receive Coin</h5>
          </div>

          <div className="card-body">

            <div className="table-responsive mb-0">

              <div className="form-group">
                <label htmlFor="receiveAddress">
                  <span className="receive_coin_type"></span> Scan the QR code or copy the address to receive coin.</label>
              </div>
             
              <div className="info_box text-center animated rubberBand ">
                <canvas id="canvas" ></canvas>
              </div>

              <div className="input-group mt-15 mb-20 ">
                <select className="form-control" onChange={this.handleClick} value={this.state.qraddress}>
                  {this.state.Addresses.map(item => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>

                <div className="input-group-append">
                  <CopyToClipboard onCopy={() => toast.warning("Copy to clipboard.")} text={this.state.qraddress}>
                    <button className="btn btn-sm btn-primary"><i className="fa fa-fw fa-copy"></i></button>
                  </CopyToClipboard>
                </div>

              </div>

              <div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}
export default connect(mapStateToProps)(Receive);





