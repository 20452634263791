import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";

class Privacypolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {}

    render() {
        return (
            <div>
                <div className="row justify-content-center">
                    <div className="col-md-8 mt-5">
                        <div>
                            <h1 style={{ paddingTop: "3pt", paddingLeft: "51pt", textIndent: "0pt", textAlign: "center" }}>PRIVACY POLICY</h1>

                            <p style={{ paddingLeft: "65pt", textIndent: "0pt", textAlign: "center" }}>Please read our Privacy Policy carefully as it explains the following:</p>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "151%", textAlign: "left" }}>
                                In order to use the App, you must first acknowledge and agree to our Privacy Policy. You cannot use the App without first accepting our Privacy Policy.
                            </p>
                            <h2 style={{ paddingTop: "11pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>GENERAL</h2>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Oduwa Blockchain Solutions LLC provides the Oduwa Trust Wallet Application, which is a crypto wallet or storage.
                            </p>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Oduwa Blockchain Solutions LLC ("Oduwa Blockchain Solutions LLC", "we", "us") recognizes and understands the importance of the privacy of its users ("users", "You") and
                                wants to respect their desire to store and access personal information in a private and secure manner. This Privacy Policy applies to our application and describes how
                                Oduwa Blockchain Solutions LLC manages, stores, and utilizes your Personal Data through its Products.
                            </p>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                In order to use our app, we require you to consent to the collection and processing of your Personal Data before you start using the app. If you do not agree with the
                                terms of this Privacy Policy, you may not use in any manner the app.
                            </p>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Oduwa Blockchain Solutions LLC is committed to protecting the privacy of all of its users Personal Data and providing a secure, user-controlled environment for the use
                                of the app.
                            </p>

                            <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>PERMISSIONS REQUIRED</h2>

                            <ul id="l1">
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}>INTERNET</p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}>NOTIFICATION</p>
                                </li>
                            </ul>

                            <p style={{ paddingTop: "11pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>Link to the privacy policy of third party service providers used by the app</p>

                            <ul id="l2">
                                <li data-list-text="">
                                    <p className="s1" style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}>
                                        Google Play Services
                                    </p>
                                </li>
                                <li data-list-text="">
                                    <p className="s1" style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}>
                                        Apple App Store Services
                                    </p>
                                </li>
                            </ul>

                            <h2 style={{ paddingTop: "4pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>LOG DATA</h2>
                            <p style={{ paddingTop: "3pt", paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Oduwa Blockchain Solutions LLC wants to inform you that whenever you use our Service, in case of an error in the app, we collect data and information (through
                                third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating
                                system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
                            </p>

                            <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>COOKIES &amp; USAGE DATA</h2>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "justify" }}>
                                Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit
                                and are stored on your device's internal memory.
                            </p>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve
                                their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies,
                                you may not be able to use some portions of this Service.
                            </p>

                            <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>USAGE DATA</h2>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                When you access the Service with a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use,
                                your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers
                                and other diagnostic data ("Usage Data").
                            </p>
                            <h2 style={{ paddingTop: "9pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>TRACKING COOKIES DATA</h2>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
                            </p>
                            <p style={{ paddingTop: "10pt", paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your
                                device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our Service.
                            </p>
                            <p style={{ paddingTop: "3pt", paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some
                                portions of our Service.
                            </p>
                            <p style={{ paddingTop: "10pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>Examples of Cookies we use:</p>

                            <ul id="l3">
                                <li data-list-text="●">
                                    <h5 style={{ paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}>
                                        <b>Session Cookies. </b>
                                        <span className="p">We use Session Cookies to operate our Service.</span>
                                    </h5>
                                </li>
                                <li data-list-text="●">
                                    <h5 style={{ paddingTop: "6pt", paddingLeft: "23pt", textIndent: "-18pt", lineHeight: "143%", textAlign: "left" }}>
                                        <b>Preference Cookies.</b> <span className="p">We use Preference Cookies to remember your preferences and various settings.</span>
                                    </h5>
                                </li>
                                <li data-list-text="●">
                                    <h5 style={{ paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}>
                                        <b>Security Cookies.</b> <span className="p">We use Security Cookies for security purposes.</span>
                                    </h5>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                    <h5 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                                        {" "}
                                        <b> USE OF DATA</b>
                                    </h5>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                    <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>Oduwa Blockchain Solutions LLC uses the collected data for various purposes:</p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}>To provide and maintain our Service</p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingTop: "5pt", paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}>To notify you about changes to our Service</p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingTop: "6pt", paddingLeft: "23pt", textIndent: "-18pt", lineHeight: "143%", textAlign: "left" }}>
                                        To allow you to participate in interactive features of our Service when you choose to do so
                                    </p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}>To provide customer support</p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingTop: "6pt", paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}>
                                        To gather analysis or valuable information so that we can improve our Service
                                    </p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingTop: "5pt", paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}>To monitor the usage of our Service</p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingTop: "6pt", paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}>To detect, prevent and address technical issues</p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingTop: "6pt", paddingLeft: "23pt", textIndent: "-18pt", lineHeight: "146%", textAlign: "left" }}>
                                        To provide you with news, special offers, and general information about other goods, services, and events which we offer that are similar to those that you have
                                        already purchased or enquired about unless you have opted not to receive such information
                                    </p>
                                </li>
                            </ul>

                            <h2 style={{ paddingTop: "9pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>YOUR PERSONAL DATA</h2>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Oduwa Blockchain Solutions LLC do not collect any or transmit any user personal information.
                            </p>

                            <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>SERVICE PROVIDERS</h2>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>We may employ third-party companies and individuals due to the following reasons:</p>

                            <ul id="l4">
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}>To facilitate our Service;</p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}>To provide the Service on our behalf;</p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingTop: "3pt", paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}>To perform Service-related services; or</p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}>To assist us in analyzing how our Service is used.</p>
                                </li>
                            </ul>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our
                                behalf. However, they are obligated not to disclose or use the information for any other purpose.
                            </p>

                            <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>CHILDREN’S PRIVACY</h2>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. In the case We
                                discover that a child under 13 has provided me with personal information, We immediately delete this from our servers. If you are a parent or guardian and you are aware
                                that your child has provided us with personal information, please contact us so that We will be able to do the necessary actions.
                            </p>

                            <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>BEHAVIORAL REMARKETING</h2>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Oduwa Blockchain Solutions LLC uses remarketing services to advertise on third-party websites to you after you visited our Service. We and our third-party vendors use
                                cookies to inform, optimise and serve ads based on your past visits to our Service.
                            </p>
                            <h2 style={{ paddingTop: "9pt", paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}>Google Ads (AdWords)</h2>
                            <p style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}>Google Ads (AdWords) remarketing service is provided by Google Inc.</p>
                            <p style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                <a href="http://www.google.com/settings/ads" className="a" target="_blank">
                                    You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page:{" "}
                                </a>
                                <a href="http://www.google.com/settings/ads" target="_blank">
                                    http://www.google.com/settings/ads
                                </a>
                            </p>
                            <p style={{ paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}>Google also recommends installing the Google Analytics Opt-out Browser Add-on</p>
                            <p style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                <a href="https://tools.google.com/dlpage/gaoptout" className="a" target="_blank">
                                    -{" "}
                                </a>
                                https://tools.google.com/dlpage/gaoptout - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from
                                being collected and used by Google Analytics.
                            </p>
                            <p style={{ paddingLeft: "41pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                <a href="https://policies.google.com/privacy?hl=en" className="a" target="_blank">
                                    For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:{" "}
                                </a>
                                <a href="https://policies.google.com/privacy?hl=en" target="_blank">
                                    https://policies.google.com/privacy?hl=en
                                </a>
                            </p>
                            <h2 style={{ paddingLeft: "41pt", textIndent: "0pt", lineHeight: "13pt", textAlign: "left" }}>Facebook</h2>
                            <p style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "0pt", textAlign: "left" }}>Facebook remarketing service is provided by Facebook Inc.</p>
                            <p style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "0pt", lineHeight: "151%", textAlign: "left" }}>
                                <a href="https://www.facebook.com/help/164968693837950" className="a" target="_blank">
                                    You can learn more about interest-based advertising from Facebook by visiting this page:{" "}
                                </a>
                                <a href="https://www.facebook.com/help/164968693837950" target="_blank">
                                    https://www.facebook.com/help/164968693837950
                                </a>
                            </p>
                            <p style={{ paddingTop: "3pt", paddingLeft: "41pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                <a href="https://www.facebook.com/help/568137493302217" className="a" target="_blank">
                                    To opt-out from Facebook's interest-based ads, follow these instructions from Facebook:{" "}
                                </a>
                                <a href="https://www.facebook.com/help/568137493302217" target="_blank">
                                    https://www.facebook.com/help/568137493302217
                                </a>
                            </p>
                            <p style={{ paddingLeft: "41pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                <a href="http://www.aboutads.info/choices/" className="a" target="_blank">
                                    Facebook adheres to the Self-Regulatory Principles for Online Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from
                                    Facebook and other participating companies through the Digital Advertising Alliance in the USA{" "}
                                </a>
                                <a href="http://youradchoices.ca/" className="a" target="_blank">
                                    http://www.aboutads.info/choices/, the Digital Advertising Alliance of Canada in Canada{" "}
                                </a>
                                <a href="http://www.youronlinechoices.eu/" className="a" target="_blank">
                                    http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in Europe{" "}
                                </a>
                                <a href="https://www.facebook.com/privacy/explanation" className="a" target="_blank">
                                    http://www.youronlinechoices.eu/, or opt-out using your mobile device settings. For more information on the privacy practices of Facebook, please visit Facebook's
                                    Data Policy:{" "}
                                </a>
                                <a href="https://www.facebook.com/privacy/explanation" target="_blank">
                                    https://www.facebook.com/privacy/explanation
                                </a>
                            </p>

                            <h2 style={{ paddingTop: "8pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>UPDATES OR CHANGES TO OUR PRIVACY POLICY</h2>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Occasionally, we may change or update this Privacy Policy to allow us to use or share your previously collected Personal Data for other purposes. If Oduwa Blockchain
                                Solutions LLC would use your Personal Data in a manner materially different from that stated at the time of the collection, we will provide you with a notice on our
                                Website and in our Oduwa Trust Wallet Mobile Application indicating that the Privacy Policy has been changed or updated and request you to agree with the updated or
                                changed Privacy Policy.
                            </p>

                            <h2 style={{ paddingTop: "8pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>CONTROLLER</h2>

                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "240%", textAlign: "left" }}>
                                <a href="mailto:Support@oduwablockchain.com" className="a" target="_blank">
                                    Oduwa Blockchain Solutions LLC{" "}
                                </a>
                                <a href="mailto:Support@oduwablockchain.com" target="_blank">
                                    Support@oduwablockchain.com
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {}
export default connect(mapStateToProps)(Privacypolicy);
