import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";

class Disclaimer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {}

    render() {
        return (
            <div>
                <div className="row justify-content-center">
                    <div className="col-md-8 mt-5">
                        <div>
                            <h1 style={{ paddingTop: "3pt", paddingLeft: "7pt", textIndent: "0pt", textAlign: "left" }}>DISCLAIMER</h1>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <p style={{ paddingLeft: "7pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                <a
                                    href="/cdn-cgi/l/email-protection#3764424747584543775853424056555b58545c545f565e591954585a"
                                    style={{ color: "black", fontFamily: "Arial, sans-serif", fontStyle: "normal", fontWeight: "normal", textDecoration: "none", fontSize: "11.5pt" }}
                                    target="_blank"
                                >
                                    If you require any more information or have any questions about our app disclaimer, please feel free to contact us by email at{" "}
                                </a>
                                <a href="/cdn-cgi/l/email-protection#73200603031c0107331c17060412111f1c1018101b121a1d5d101c1e" target="_blank">
                                    <span className="__cf_email__" data-cfemail="cc9fb9bcbca3beb88ca3a8b9bbadaea0a3afa7afa4ada5a2e2afa3a1e2">
                                        [email&nbsp;protected]
                                    </span>
                                </a>
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <p style={{ paddingLeft: "7pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Oduwa Blockchain Solutions LLC provides the Oduwa Trust Wallet Application, which is a crypto wallet or storage.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <p style={{ paddingLeft: "7pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                By downloading, accessing or using ODUWA TRUST WALLET Mobile App or any page of this app, you signify your assent to this disclaimer. The contents of this app,
                                including without limitation, all data, information, text, graphics, links and other materials are provided as a convenience to our app users and are meant to be used
                                for informational purposes only. We do not take responsibility for decisions taken by the reader based solely on the information provided in this app.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <p style={{ paddingTop: "4pt", paddingLeft: "7pt", textIndent: "0pt", textAlign: "left" }}>The app EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER</p>
                            <p style={{ paddingTop: "6pt", paddingLeft: "7pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                EXPRESS OR IMPLIED. The app makes no warranty that (1) THE APP OR THE CONTENT WILL MEET OR SATISFY YOUR REQUIREMENTS (2) THE APP SHALL HAVE NO RESPONSIBILITY FOR ANY
                                DAMAGE TO YOUR PHONE OR TABLET OR LOSS OF DATA THAT RESULTS FROM YOUR USE OF THE APP OR ITS CONTENT.
                            </p>
                            <p style={{ paddingTop: "7pt", paddingLeft: "7pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                YOU AGREE TO HOLD ODUWA TRUST WALLET MOBILE APP, AND EACH OF ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS FROM AND AGAINST ANY AND ALL CLAIMS, ACTIONS, DEMANDS,
                                LIABILITIES, JUDGMENTS AND SETTLEMENTS, INCLUDING WITHOUT LIMITATION, FROM ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR ANY OTHER
                                CLAIM YOU MAY INCUR IN CONNECTION WITH YOUR USE OF THIS APP, INCLUDING, WITHOUT LIMITATION, ANY ECONOMIC HARM, LOST PROFITS, DAMAGES TO BUSINESS, DATA OR PHONE SYSTEMS,
                                OR ANY DAMAGES RESULTING FROM RELIANCE ON ANY CONTENT OR RESULTING FROM ANY INTERRUPTIONS, WORK STOPPAGES, PHONE OR TABLET FAILURES,DELETION OF FILES, ERRORS,
                                OMISSIONS, INACCURACIES, DEFECTS, VIRUSES, DELAYS OR MISTAKES OF ANY KIND.
                            </p>
                            <p style={{ paddingTop: "7pt", paddingLeft: "7pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                The app may include inaccuracies and typographical errors. Changes and improvements are periodically made to the app and the information therein. We do not warrant or
                            </p>
                            <p style={{ paddingLeft: "1pt", textIndent: "0pt", lineHeight: "149%", textAlign: "left" }}>
                                assume any legal liability or responsibility for the completeness, or usefulness of any information, service.
                            </p>
                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {}
export default connect(mapStateToProps)(Disclaimer);

