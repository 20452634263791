import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";


import { use } from "passport";
import _ from "lodash";
var ws;
var messageID;
var subscriptions = [];
var pendingMessages;
var subscribeAddressesId = "";

class ContentHeader extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      Balance: []
    };
  }

  componentWillMount() {
    UserService.getBalance(this.props.coin).then(
      response => {
           if(response.data.code == 100)
           {
            this.setState({
              Balance: response.data
          });
         }
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    return (
      <div>
        <a href="#!" className="btn btn-primary mb-2 animated zoomInDown animationDelay500"><i className="lar la-check-circle"></i>  {this.state.Balance.ConfirmedBalance} {this.props.coin.toUpperCase()}  ({parseFloat(this.state.Balance.Confirmed_USDBalance).toFixed(4)} USD)</a>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}
export default connect(mapStateToProps)(ContentHeader);
