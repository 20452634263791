
import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from "react-validation/build/form";
import _ from "lodash";

class PassphraseRecoveryconfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fillmnemonic: [],
      values: [],
      loading: false,
    };
    this.getRandom = this.getRandom.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }



  componentWillMount() {
     var splitmnemonic = this.props.mnemonic.split(" ");
     var rndno = this.getRandom(splitmnemonic, 5);
     var finalrnd = _.orderBy(rndno, ['index'], ['asc']);
     this.setState
      ({
        fillmnemonic: finalrnd
      })
  }

  handleChange(i, e) {
    this.setState({
      values: { ...this.state.values, [i]: e.target.value }
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    var ismatch = true;
    this.setState({
      loading: true
    });

    if (this.state.values.length < 5) {
      toast.error("Please fill All Words");
      this.setState({
        loading: false,
      });
      ismatch = false;
      return;

    }
    else {
     for (var i = 0; i < this.state.fillmnemonic.length; i++) {
        if (this.state.fillmnemonic[i].value.trim() != this.state.values[i].trim()) {
          toast.error("Recovery Phrase word does not match.");
          this.setState({
            loading: false,
          });
          ismatch = false;
          break;
        }
      }
    }

    if (ismatch) {
      this.props.history.push("/login");
    }
  }



  getRandom(arr, n) {
    var result = [];
    while (result.length < n) {
      var random = Math.floor(Math.random() * arr.length);
      var exist = !!result.find(x => x.value === arr[random])
      if (!exist) {
        var objran = { "index": random, "value": arr[random] }
        result.push(objran);
      }
    }
    return result;
  }


  render() {
    return (
     
        <div className="col-md-5 mt-5" >
        
            <ToastContainer />
            <div className="card animated zoomInDown">

              <div className="card-header">
                <h5 className="card-title animated bounceInLeft  animationDelay500">Recovery Passphrase Confirm</h5>
              </div>
              <div className="card-body">
                <Form
                  onSubmit={this.handleNext}>
                  <h5 className="text-warning mb-5">
                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none"  strokeLinecap="round"  className="mr-2"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>  &nbsp;    Confirm that you backed up your Recovery Phrase by filling the  &nbsp;

                    {this.state.fillmnemonic.map((data, index) => (
                      index < 4 ? (
                        (data.index + 1) + " , "
                      ) : (
                        (data.index + 1)
                      )
                    ))
                    }

                    &nbsp;  Below Text Box
                  </h5>

                  {this.state.fillmnemonic.map((data, index) => (
                    <div className="input-group center mt-2 mb-2" key={index}>
                      <label className="label" htmlFor={"txtword" + index} > {"Enter " + (data.index + 1) + " word"} </label>
                      <input
                        type='text'
                        name={"txtword" + index}
                        className="form-control"
                        onChange={this.handleChange.bind(this, index)} />
                    </div>
                  ))
                  }
                </Form>
              </div>

            <div className="card-footer  text-center">
              <button className="btn btn-primary" disabled={this.state.loading} onClick={this.handleSubmit} >   {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )} Confirm </button>
            </div>
           
            </div>
        
        </div>
     
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, user } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
    user
  };
}

export default connect(mapStateToProps)(PassphraseRecoveryconfirm);
