import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";

class TermsConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {}

    render() {
        return (
            <div>
                <div className="row justify-content-center">
                    <div className="col-md-8 mt-5">
                        <div>
                            <h1 style={{ paddingTop: "3pt", paddingLeft: "141pt", textIndent: "0pt", textAlign: "center" }}>TERMS &amp; CONDITIONS</h1>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                                By downloading, browsing, accessing, or using this Oduwa Trust Wallet Application (“ <b>Oduwa Trust Wallet Mobile Application</b>”), you agree to be bound by these
                                Terms and Conditions of Use. We reserve the right to amend these terms and conditions at any time. If you disagree with any of these Terms and Conditions of Use, you
                                must immediately discontinue your access to the Oduwa Trust Wallet Mobile Application and your use of the services offered on the Oduwa Trust Wallet Mobile Application
                            </p>
                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                Continued use of the Oduwa Trust Wallet Mobile Application will constitute acceptance of these Terms and Conditions of Use, as may be amended from time to time
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>TERMS TO USE</h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <ul id="l1">
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "148%", textAlign: "left" }}>
                                        Oduwa Blockchain Solutions LLC is committed to ensuring that the app is as useful and efficient as possible. For that reason, we reserve the right to make
                                        changes to the app or to charge for its services, at any time and for any reason. We will never charge you for the app or its services without making it very
                                        clear to you exactly what you’re paying for.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                        The Oduwa Trust Wallet app stores and processes personal data that you have provided to us, in order to provide Oduwa Blockchain Solutions LLC Service. It’s
                                        your responsibility to keep your phone and access to the app secure. We therefore recommend that you do not jailbreak or root your phone, which is the process
                                        of removing software restrictions and limitations imposed by the official operating system of your device. It could make your phone vulnerable to
                                        malware/viruses/malicious programs, compromise your phone’s security features and it could mean that the Oduwa Trust Wallet app won’t work properly or at all.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "148%", textAlign: "left" }}>
                                        Oduwa Blockchain Solutions LLC cannot always take responsibility for the way you use the app i.e. You need to make sure that your device stays charged – if it
                                        runs out of battery and you can’t turn it on to avail the Service, Oduwa Blockchain Solutions LLC cannot accept responsibility.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                        At some point, we may wish to update the app. The app is available for both Android and iOS– the requirements for the system (and for any additional systems we
                                        decide to extend the availability of the app to) may change, and you’ll need to download the updates if you want to keep using the app. It is your
                                    </p>
                                    <p style={{ paddingTop: "3pt", paddingLeft: "41pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                        responsibility to keep the app version up to date. Oduwa Blockchain Solutions LLC does not promise that it will always update the app so that it is relevant to
                                        you and/or works with the Android or iOS version that you have installed on your device. However, you promise to always accept updates to the application when
                                        offered to you, we may also wish to stop providing the app, and may terminate use of it at any time without giving notice of termination to you.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "146%", textAlign: "left" }}>
                                        We strongly recommend that you only download the Oduwa Trust Wallet applications from Google Play or App Store. Doing so will ensure that your apps are
                                        legitimate and safe from malicious software.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                    <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>SECURITY TIPS</h2>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                    <ul id="l2">
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}>Only download Oduwa Trust Wallet from the official sources.</p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                Store your recovery phrase in writing or use durable hardware backup products and keep the backup somewhere safe.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                If it's just stored on your computer, the file may be corrupted, lost, or your computer may break.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}>Never give your recovery phrase or private keys to any third party.</p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                Don't store your recovery phrase on a cloud service such as Dropbox, Google Drive, Gmail, iCloud, or OneDrive.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                These services may be easier to break into, especially if you don't have 2FA. It also means third parties are holding the secret keys to all your funds.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                Lock your funds with a secure withdrawal password that only you know, or a combination of a password and biometric authentication.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                You can set and change this in the settings of the application, or during the wallet creation process.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}>Each wallet can have its own encryption settings, according to its usage.</p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingTop: "6pt", paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "148%", textAlign: "left" }}>
                                                Create a second layer of authentication by securing Oduwa Trust Wallet with a secure pin or pattern. In Android and iOS, you can set this in
                                                .../settings/Change Security Lock.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                Oduwa Trust Wallet cannot guarantee the security of your funds If your iPhone is jailbroken or your Android is rooted.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                A rooted or jailbroken device can be exploited in potentially hazardous ways beyond the application's control.
                                            </p>
                                            <h2 style={{ paddingTop: "3pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>SERVICE TERMS</h2>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "148%", textAlign: "left" }}>
                                                Oduwa Trust Wallet is not a bank. You do not have an "account" with us. Oduwa Trust Wallet cannot access or control your funds, nor does it collect or
                                                transmit any kind of personal information.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "146%", textAlign: "left" }}>
                                                A noncustodial wallet, where Users have full control of their OWC,OWDT &amp; BTC and Private keys.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "148%", textAlign: "left" }}>
                                                Oduwa Trust Wallet is an interface. It allows you to interact with many blockchains without ever giving up your private keys, the "passcode" to your
                                                funds.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                We can never recover your funds if you lose your recovery phrase or your private keys.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                We can never recover your funds if you reveal your recovery phrase or your private keys to a third party (this allows them to irretrievably steal your
                                                funds).
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                Once a transaction has confirmed on the blockchain, it cannot be reversed or cancelled.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                Oduwa Trust Wallet cannot send transactions on your behalf. Only you can create a transaction. You are always in full control of your funds.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                A BIP39 recovery phrase is a phrase with 12 to 24 words that generates your private keys. This recovery phrase generates your wallet.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                Every address and private key generated from your recovery phrase is permanently tied to it.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                A recovery phrase is not your Oduwa Trust Wallet password. You can change your Oduwa Trust Wallet password in-app using your recovery phrase.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "147%", textAlign: "left" }}>
                                                As long as you alone hold your recovery phrase, only you will be able to send your funds.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "146%", textAlign: "left" }}>
                                                It's essential that you have a backup of your recovery phrase in case anything ever happens to your phone.
                                            </p>
                                        </li>
                                        <li data-list-text="">
                                            <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "146%", textAlign: "left" }}>
                                                An HD wallet allows you to interact with your funds without giving up control of your recovery phrase or private keys.
                                            </p>
                                            <h2 style={{ paddingTop: "10pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>GENERAL TERMS</h2>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                        </li>
                                        <li data-list-text="">
                                            <h5 style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "148%", textAlign: "left" }}>
                                                <b>License Grant;</b> <span className="p">Compliance with Terms of Use. The Application is licensed, not sold, and </span>
                                                <b>Oduwa Blockchain Solutions LLC</b>
                                                <span className="p">reserves all rights not expressly granted in this Agreement. Subject to the terms and conditions hereof, </span>
                                            </h5>
                                            <h5 style={{ paddingTop: "3pt", paddingLeft: "41pt", textIndent: "0pt", lineHeight: "151%", textAlign: "left" }}>
                                                <b> Oduwa Blockchain Solutions LLC</b>
                                                <span className="p">
                                                    grants you a personal, nonexclusive, non-transferable, non-sublicense able, limited license to download and use the Application on a mobile device
                                                    that you own or control.
                                                </span>
                                            </h5>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                        </li>
                                        <li data-list-text="">
                                            <h5 style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "150%", textAlign: "left" }}>
                                                <b> License Restrictions.</b>
                                                <span className="p">
                                                    Except as specifically provided herein, You may not: (i) distribute or make the Application available over a network where it could be used by
                                                    multiple devices at the same time; (ii) copy the Application; (iii) modify, adapt, translate, reverse engineer, make alterations, decompile,
                                                    disassemble or make derivative works based on the Application, except as otherwise permitted by law; or (iv) use, rent, loan, sub-license, lease,
                                                    distribute or attempt to grant other rights to the Application to third parties.
                                                </span>
                                            </h5>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                        </li>
                                        <li data-list-text="">
                                            <h5 style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                                <b> Your Warranty to Oduwa Blockchain Solutions LLC.</b>
                                                <span className="p">
                                                    You represent and warrant that: (i) You have the authority to bind Yourself to this Agreement; (ii) Your use of the Application will be solely for
                                                    purposes that are permitted by this Agreement; and (iii) Your use of the Application will comply with all local and national and laws, rules, and
                                                    regulations (“Laws”).
                                                </span>
                                            </h5>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                        </li>
                                        <li data-list-text="">
                                            <h5 style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                                <b> Privacy. </b>
                                                <span className="p">By using the Application, you agree that </span> <b>Oduwa Blockchain Solutions LLC</b>
                                                <span className="p">
                                                    may collect and use certain information about you, your mobile device, your use of the Application and the Application’s performance in accordance
                                                    with the Privacy Policy, as may be amended from time to time.
                                                </span>
                                            </h5>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                        </li>
                                        <li data-list-text="">
                                            <h5 style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                                <b>Indemnity.</b> <span className="p">You agree to indemnify and hold harmless </span>
                                                <b>Oduwa Blockchain Solutions LLC</b>
                                                <span className="p">
                                                    and its affiliates, officers, directors, employees, consultants, agents and anyone providing information or software used in the Application from
                                                    any and all claims arising from, related to, or incidental to Your use of the Application.
                                                </span>
                                            </h5>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                        </li>
                                        <li data-list-text="">
                                            <h5 style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                                <b> Termination. </b>
                                                <span className="p">This Agreement is effective until terminated. </span> <b>Oduwa Blockchain Solutions LLC</b>
                                                <span className="p">
                                                    may immediately terminate this Agreement at any time at its sole discretion with or without notice to you. Additionally, Your rights under this
                                                    Agreement will terminate automatically if you fail to comply with any term(s) of this Agreement. Upon termination, all legal rights and licenses
                                                    granted to You hereunder shall terminate immediately and You shall cease all use of the
                                                </span>
                                            </h5>
                                            <p style={{ paddingTop: "3pt", paddingLeft: "41pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                                Application and destroy all copies of the Application. All sections that may be reasonably interpreted to or are intended to survive this Agreement will
                                                survive this Agreement.
                                            </p>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                        </li>
                                        <li data-list-text="">
                                            <h5 style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                                <b>Acknowledgment of Understanding/Entire Agreement.</b>
                                                <span className="p">
                                                    You acknowledge that You have read this Agreement, understand it and agree to be bound by its terms and conditions. You also agree that this
                                                    Agreement is the complete and exclusive statement of the Agreement between
                                                </span>
                                                <b>Oduwa Blockchain Solutions LLC</b>
                                                <span className="p">
                                                    and You and supersedes all proposals, representations or prior agreements, oral or written, and any other communications between
                                                </span>
                                                <b>Oduwa Blockchain Solutions LLC</b> <span className="p">and You relating to the subject matter of this Agreement.</span>
                                            </h5>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                        </li>
                                        <li data-list-text="">
                                            <h5 style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                                <b>Severability.</b>
                                                <span className="p">
                                                    You agree that the terms and conditions stated in this Agreement are severable. If any paragraph, provision, or clause in this Agreement shall be
                                                    found or be held to be invalid or unenforceable in any jurisdiction, the remainder of this agreement shall be valid and enforceable.
                                                </span>
                                            </h5>
                                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                        </li>
                                        <li data-list-text="">
                                            <h5 style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                                <b>Assignment and Transfer.</b>
                                                <span className="p">
                                                    Oduwa Blockchain Solutions LLC may assign, transfer, sell, rent or lend this Agreement, in whole or in part, at any time without notice to You. You
                                                    may not assign this Agreement or any part of it or any rights to use the Application, in whole or in part, either temporarily or permanently, to any
                                                    other party. Any attempt to do so is void.
                                                </span>
                                            </h5>
                                        </li>
                                    </ul>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                    <h5 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                                        <b>DISCLAIMER AND EXCLUSION OF LIABILITY</b>
                                    </h5>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "148%", textAlign: "left" }}>
                                        The Oduwa Trust Wallet Mobile Application, the Services, the information on the Oduwa Trust Wallet Mobile Application and use of all related facilities are
                                        provided on an "as is, as available" basis without any warranties whether express or implied.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "148%", textAlign: "left" }}>
                                        To the fullest extent permitted by applicable law, we disclaim all representations and warranties relating to the Oduwa Trust Wallet Mobile Application and its
                                        contents, including in relation to any inaccuracies or omissions in the Oduwa Trust Wallet Mobile Application, warranties of merchantability, quality, fitness
                                        for
                                    </p>
                                    <p style={{ paddingTop: "3pt", paddingLeft: "41pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                        a particular purpose, accuracy, availability, non-infringement or implied warranties from course of dealing or usage of trade.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                        We do not warrant that the Oduwa Trust Wallet Mobile Application will always be accessible, uninterrupted, timely, secure, error free or free from computer
                                        virus or other invasive or damaging code or that the Oduwa Trust Wallet Mobile Application will not be affected by any acts of God or other force majeure
                                        events, including inability to obtain or shortage of necessary materials, equipment facilities, power or telecommunications, lack of telecommunications
                                        equipment or facilities and failure of information technology or telecommunications equipment or facilities.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "146%", textAlign: "left" }}>
                                        While we may use reasonable efforts to include accurate and up-to-date information on the Oduwa Trust Wallet Mobile Application, we make no warranties or
                                        representations as to its accuracy, timeliness, or completeness.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                        We shall not be liable for any acts or omissions of any third parties howsoever caused, and for any direct, indirect, incidental, special, consequential or
                                        punitive damages, howsoever caused, resulting from or in connection with the Oduwa Trust Wallet Mobile Application and the services offered in the Oduwa Trust
                                        Wallet Mobile Application, your access to, use of or inability to use the Oduwa Trust Wallet Mobile Application or the services offered in the Oduwa Trust
                                        Wallet Mobile Application, reliance on or downloading from the Oduwa Trust Wallet Mobile Application and/or services, or any delays, inaccuracies in the
                                        information or in its transmission including but not limited to damages for loss of business or profits, use, data or other intangible, even if we have been
                                        advised of the possibility of such damages.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                        We shall not be liable in contract, tort (including negligence or breach of statutory duty) or otherwise howsoever and whatever the cause thereof, for any
                                        indirect, consequential, collateral, special or incidental loss or damage suffered or incurred by you in connection with the Oduwa Trust Wallet Mobile
                                        Application and these Terms and Conditions of Use. For the purposes of these Terms and Conditions of Use, indirect or consequential loss or damage includes,
                                        without limitation, loss of revenue, profits, anticipated savings or business, loss of data or
                                    </p>
                                    <p style={{ paddingTop: "3pt", paddingLeft: "41pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                        goodwill, loss of use or value of any equipment including software, claims of third parties, and all associated and incidental costs and expenses.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "146%", textAlign: "justify" }}>
                                        The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer that cannot be excluded or limited
                                        are affected.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                    <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>INTELLECTUAL PROPERTY RIGHTS</h2>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "149%", textAlign: "left" }}>
                                        All editorial content, information, photographs, illustrations, artwork and other graphic materials, and names, logos and trade marks on the Oduwa Trust Wallet
                                        Mobile Application are protected by copyright laws and/or other laws and/or international treaties, and belong to us and/or our suppliers, as the case may be.
                                        These works, logos, graphics, sounds or images may not be copied, reproduced, retransmitted, distributed, disseminated, sold, published, broadcasted or
                                        circulated whether in whole or in part, unless expressly permitted by us and/or our suppliers, as the case may be.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "148%", textAlign: "left" }}>
                                        Nothing contained on the Oduwa Trust Wallet Mobile Application should be construed as granting by implication, estoppel, or otherwise, any license or right to
                                        use any trademark displayed on the Oduwa Trust Wallet Mobile Application without our written permission. Misuse of any trademarks or any other content displayed
                                        on the Oduwa Trust Wallet Mobile Application is prohibited.
                                    </p>
                                    <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                                </li>
                                <li data-list-text="●">
                                    <p style={{ paddingLeft: "41pt", textIndent: "-18pt", lineHeight: "148%", textAlign: "left" }}>
                                        We will not hesitate to take legal action against any unauthorised usage of our trade marks, name or symbols to preserve and protect its rights in the matter.
                                        All rights not expressly granted herein are reserved. Other product and company names mentioned herein may also be the trade marks of their respective owners.
                                    </p>
                                </li>
                            </ul>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>COPYRIGHT INFRINGEMENTS</h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "justify" }}>
                                We respect the intellectual property rights of others. If you believe that any material available on or through the App infringes upon any copyright you own or control,
                                please immediately notify us using the contact information provided below (a “Notification”). A copy of your Notification will be sent to the person who posted or
                                stored the material
                            </p>
                            <p style={{ paddingTop: "3pt", paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "justify" }}>
                                addressed in the Notification. Please be advised that pursuant to federal law you may be held liable for damages if you make material misrepresentations in a
                                Notification. Thus, if you are not sure that material located on or linked to by the App infringes your copyright, you should consider first contacting an attorney.
                            </p>
                            <h2 style={{ paddingTop: "9pt", paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>CHANGES TO THIS TERMS AND CONDITIONS</h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "150%", textAlign: "left" }}>
                                I may update our Terms and Conditions from time to time. Thus, you are advised to review this page periodically for any changes. I will notify you of any changes by
                                posting the new Terms and Conditions on this page. These changes are effective immediately after they are posted on this page.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <h2 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>CONTACT US</h2>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}></p>
                            <p style={{ paddingLeft: "5pt", textIndent: "0pt", lineHeight: "151%", textAlign: "left" }}>
                                <a href="/cdn-cgi/l/email-protection#3d6e484d4d524f497d5259484a5c5f51525e565e555c5453135e5250" className="a" target="_blank">
                                    If you have any questions, do not hesitate to contact me at
                                </a>
                                <a href="/cdn-cgi/l/email-protection#cb98bebbbba4b9bf8ba4afbebcaaa9a7a4a8a0a8a3aaa2a5e5a8a4a6" target="_blank">
                                    <span className="__cf_email__" data-cfemail="2c7f595c5c435e586c4348595b4d4e40434f474f444d4542024f434102">
                                        [email&nbsp;protected]
                                    </span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {}
export default connect(mapStateToProps)(TermsConditions);

