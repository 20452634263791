
import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import Form from "react-validation/build/form";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleAccessWallet = this.handleAccessWallet.bind(this);
    this.onChangemnemonic = this.onChangemnemonic.bind(this);
    this.state = {
      mnemonic: "",
      loading: false,
      errors: []
    };
  }

  onChangemnemonic(e) {
    this.setState({
      mnemonic: e.target.value,
    });
  }

  handleAccessWallet(e) {
    e.preventDefault();
    if (!this.state.mnemonic) {
      toast.error("mnemonic is Required");
      this.setState({
        loading: false,
      });
      return;
    }

    else {
      var words = this.state.mnemonic.split(" ");
      if (words.length != 18) {
        toast.error("Your Phrase must 18 words");
        this.setState({
          loading: false,
        });
        return;
      }
      else {
        this.setState({ loading: true });
        const { dispatch, history } = this.props;
        dispatch(login(this.state.mnemonic))
          .then((resp) => {
            if (resp.code == 100) 
            {
              this.setState({
                loading: false
              });
              history.push("/dashboard/OWC");
              window.location.reload();
            }
            else 
            {
              toast.error(resp.msg);
              this.setState({
                loading: false,
              });
            }
          })
          .catch(() => {
            this.setState({
              loading: false
            });
          });
      }
    }
  }
  render() {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      return <Redirect to="/dashboard/OWC" />;
    }
    return (
      <div className=" h-100">
        <ToastContainer />
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content animated zoomInDown">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">

                      <div className="text-center mb-3">
                        <img src="../assets/images/logo.png" alt="" />
                      </div>
                      <h4 className="text-center mb-4">Sign in your account</h4>
                      <Form
                        onSubmit={this.handleAccessWallet}>
                        <div className="form-group">
                          <label htmlFor="mnemonic">Passphrase  <span className="text-danger">*</span></label>
                          <textarea rows="5" className="form-control font-weight-bold animated bounceInRight  animationDelay500" id="mnemonic" value={this.state.mnemonic} onChange={this.onChangemnemonic} />
                        </div>

                        <div className="form-group">
                          <button
                            className="btn btn-primary btn-block"
                            disabled={this.state.loading}
                          >
                            {this.state.loading && (
                              <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Access Your Wallet</span>
                          </button>
                          <h4 className="text-center mb-2 mt-2" >OR</h4>
                          <a href="/CreateWallet" className="btn btn-warning btn-block"> Create Wallet </a>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message
  };
}

export default connect(mapStateToProps)(Login);
