import React from "react";
import { Modal, Button, FormControl } from "react-bootstrap";
import { confirmable, createConfirmation } from "react-confirm";

class Confirmationpop extends React.Component {
  handleOnClick(index) {
    const { proceed } = this.props;
    return () => {
      proceed({
        index
      });
    };
  }

  render() {
    const { show, message,fee,Amount,Toaddress,ticker  } = this.props;
   return (
      <div className="static-modal">
        <Modal show={show}  backdrop="static" style={{ cursor: "auto" }}>
          <Modal.Header>
          <h4 className="modal-title text-danger">{message }</h4>
           
          </Modal.Header>
          <Modal.Body>
           <div className="row">
                <div className="form-group col-md-12">
                  <label htmlFor="txtToAddress" className="animated bounceInLeft  animationDelay100" style={{color:"black"}} >To Address </label>
                  <input className="form-control form-control-user animated bounceInLeft  animationDelay100" id="txtToAddress"
                    value={Toaddress} readOnly style={{color:"black"}}/>
                </div>

                <div className="form-group col-md-12">
                  <label htmlFor="txtAmount" className="animated bounceInLeft  animationDelay100 " style={{color:"black"}}> Amount </label>
                  <input className="form-control form-control-user animated bounceInLeft  animationDelay100" id="txtAmount"
                    value={Amount + " " + ticker} readOnly style={{color:"black"}} />
                </div>

                <div className="form-group col-md-12">
                  <label htmlFor="txtfee" className="animated bounceInLeft  animationDelay100" style={{color:"black"}}>Fee  </label>
                  <input className="form-control form-control-user animated bounceInLeft  animationDelay100" id="txtfee"
                    value={fee + " " +ticker  } readOnly style={{color:"black"}} />
                </div>
              </div>

            </Modal.Body>
          <Modal.Footer>
        <Button  variant ="danger" bsStyle="danger" onClick={this.handleOnClick(false)}>Cancel</Button>
        <Button className="btn btn-primary" bsStyle="primary" backdrop="static" onClick={this.handleOnClick(true)}>Confirm </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export const confirm = createConfirmation(confirmable(Confirmationpop));
