import React, { Component } from "react";
import { Redirect,Link } from 'react-router-dom';
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import UserService from "../services/user.service";
import PassphraseRecoveryconfirm from "./Passphrase-Recovery-confirm-compoent";

class CreateWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isdisplaybackupconfirm:false,
      mnemonic: ""
    };
    this.handleNext = this.handleNext.bind(this);
  }

  handleNext(e) {
    this.setState({
      isdisplaybackupconfirm : true
    });
  }

  componentWillMount() {
    UserService.CreateWallet().then(
      response => {
        if (response.data.code == 100) {
          this.setState({
            mnemonic: response.data.mnemonic
          })
        }
        else {
          toast.error(response.data.msg);
        }
      }
    );
  }

  render() {
    const { isLoggedIn} = this.props;

    if (isLoggedIn) {
      return <Redirect to="/dashboard/OWC" />;
    }

    var words = this.state.mnemonic.split(" ");
    
    return (
      <div>
      <div className="row justify-content-center">
    
           {
              !this.state.isdisplaybackupconfirm ?
                <div className="col-md-8 mt-5" >
              <ToastContainer />
              <div className="card animated zoomInDown">
                <div className="card-header">
                  <h5 className="card-title animated bounceInLeft  animationDelay500">Recovery Passphrase</h5>
                </div>
    
                <div className="card-body">
                  <h6 className="text-warning mb-3"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round"  className="mr-2"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg> &nbsp; The recovery phrase is the master key to your funds. Never share it with anyone else.</h6>
                  <h6 className="text-warning mb-1"><svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round"  className="mr-2"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg> &nbsp; if you lose your recovery phrase, not even us can recovery your funds. </h6>
                <div className="input-group mt-5 mb-4">
                    {
                      words.map(function (word) {
                        return <div key={word}><button type="button" className="btn btn-outline-primary btn-sm mb-2" >{word} </button> &nbsp; </div>
    
                      })
                    }
                 </div>
    
                  <div className="col-md-auto text-center">
                    <CopyToClipboard onCopy={() => toast.warning("Copy to clipboard.")} text={this.state.mnemonic}>
                      <button className="btn btn-warning mb-2"><i className="fa fa-fw fa-copy animated bounceInRight animationDelay600"></i> Copy</button>
                    </CopyToClipboard>
                  </div>
                  </div>
                <div className="card-footer  text-center">
                <button className="btn btn-primary"  onClick={this.handleNext}>I Write down word </button>
                </div>
              </div>
            </div>
                 :
                <PassphraseRecoveryconfirm mnemonic={this.state.mnemonic } history={this.props.history} />
           }
     </div>

      <div className="footer text-center pl-2" >
            <div className="copyright text-center">
                <p>Copyright ©  <a href="/" target="_blank">Oduwa Trust Wallet</a> 2021</p>
            </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isLoggedIn, user } = state.auth;
  const { message } = state.message;
  return {
    isLoggedIn,
    message,
    user
  };
}

export default connect(mapStateToProps)(CreateWallet);
