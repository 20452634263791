import React, { Component } from "react";
import UserService from "../services/user.service";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { confirm as confirmComplex } from "./confirmation-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Send extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 0,
      Toaddress: "",
      txs: "",
      fee: 0,
      loading: false,
      errors: [],
      cointicker: ""
    };

    this.handleClick = this.handleClick.bind(this);
    this.onChangamount = this.onChangamount.bind(this);
    this.onChangeToaddress = this.onChangeToaddress.bind(this);
    }

  componentWillMount() {
    const user = this.props.user;
    if (!user) {
      return <Redirect to="/login" />;
    }

    var cointicker = this.props.coin;
    
    if (cointicker == undefined) {
      this.setState({
        cointicker: "OWC"
      })
    }
    else {
      this.setState({
        cointicker: cointicker
      })
      
    }
  }

  handleOnClickComplex() {
    confirmComplex({ message: "Are you sure you want Send ?", fee: this.state.fee, Amount: this.state.amount, Toaddress: this.state.Toaddress, ticker: this.state.cointicker }).then(
      ({ index }) => {
        if (index) {
          UserService.send(this.state.cointicker, this.state.txs).then(
            response => {
              if (response.code == 100) {
                toast.success(response.msg);
                this.setState({
                  loading: false,
                });
              }
              else {
                toast.error(response.msg);
                this.setState({
                  loading: false,
                })
              }
            },
            error => {
              toast.error(error);
              this.setState({
                loading: false,
              });
            })
          }
        else {
          this.setState({
            loading: false,
          });
        }
      }
    );
  }

  onChangamount(e) {
    this.setState({
      amount: e.target.value,
    });
  }

  onChangeToaddress(e) {
    this.setState({
      Toaddress: e.target.value,
    });
  }

  handleClick = (event) => {

    this.setState({
      loading: true,
    });

    event.preventDefault();
    
    if (!this.state.Toaddress) {
      toast.error("Please Enter Address");
      this.setState({
        loading: false,
      });
      return;
    }

    else if (!this.state.amount) {
      toast.error("Please Enter Amount");
      this.setState({
        loading: false,
      });
      return;
    }

    else if (parseFloat(this.state.amount) <= 0) {
      toast.error("Amount must be greater than zero.");
      this.setState({
        loading: false,
      });
      return;
    }
 
    UserService.checkbalance(this.state.cointicker, this.state.Toaddress, this.state.amount).then(
      response => {
       if (response.data.code == 900) {
          toast.error(response.data.msg);
          this.setState({
            loading: false,
          });
          return;
        }
        else if (response.data.code == 100) {
          this.setState({
            txs: (response.data.tx),
            fee: (response.data.fee)
          });
          this.handleOnClickComplex();
        }
        else {
          toast.error(response.data.msg);
          this.setState({
            loading: false,
          });
        }
      },
      error => {
        toast.error("Internal Server Error, please wait..");
        this.setState({
          loading: false,
        });
        return;
      }
    );
  }

  render() {
    const { user: currentUser } = this.props;
    if (!currentUser) {
      return <Redirect to="/login" />;
    }

    return (
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-5 animated zoomInDown ">

        <div className="card">

          <div className="card-header">
            <h5 className="card-title animated bounceInLeft  animationDelay500">Send Coin</h5>
          </div>

          <div className="card-body">

            <div className="row">
              
              <div className="form-group col-md-12">
                <label htmlFor="txtToAddress" className="animated bounceInLeft  animationDelay500">To Address  <span className="text-danger">*</span> </label>
                <input className="form-control form-control-user animated bounceInLeft  animationDelay500" id="txtToAddress"
                  placeholder="Enter To Address..." onChange={this.onChangeToaddress} />
              </div>

              <div className="form-group col-md-12">
                <label htmlFor="txtAmount" className="animated bounceInLeft  animationDelay500">Amount <span className="text-danger">*</span> </label>
                <input className="form-control form-control-user animated bounceInLeft  animationDelay500" id="txtAmount"
                  type="number" placeholder="Enter Amount..." onChange={this.onChangamount} />
              </div>

              <div className="form-group col-md-12">
                <button className="btn btn-primary btn-block" disabled={this.state.loading} onClick={this.handleClick}>
                  {this.state.loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Send</span>
                </button>

              </div>
            </div>

          </div>
        </div>

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}
export default connect(mapStateToProps)(Send);





